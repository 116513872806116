import React, {useState, useEffect} from 'react'

const DocumentListItem = (props) => {

    const [expandName, setExpandName] = useState(false);

    // const [clip, setClip] = useState(null)
    // const [docDate, setDocDate] = useState(null)
    // const [workspaceCategory, setWorkspaceCategory] = useState(null)

    // useEffect(() => {
    //     setClip(null)
    //     setDocDate(null)
    //     setWorkspaceCategory(null)
    //     props.document.map((attribute) => {
    //         if (attribute.id === 11) {
    //             setClip(attribute.value)
    //         } else if (attribute.id === 10) {
    //             setDocDate(attribute.valueDate)
    //         } else if (attribute.id === 24) {
    //             setWorkspaceCategory(attribute.value)
    //         } else {
    //             console.log('unknown custom attribute', attribute)
    //         }
    //     })
    // })

    return (
        <> 
        {/* {expandName && props.document.name.length > 40 ? <div style={{position: 'absolute', left: 650, marginBottom: 60, zIndex: 99, borderWidth: 1, borderColor: 'red', backgroundColor: '#D3D3D3'}}>{props.document.name}</div>: null} */}
            <tr>

                <td>{props.document.cabinet}</td>
                <td >
                    <a
                        href={`https://vault.netvoyage.com/neWeb2/goid.aspx?id=${props.document.id}`}
                        style={{ display: "table-cell" }}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {props.document.id}
                    </a>
                </td>
                
                <td
                    onClick={() => {setExpandName(!expandName)}}
                    onMouseLeave={() => {setExpandName(false)}}
                    style={{cursor: 'pointer'}}
                    >
                    {!expandName ? props.document.name.length > 40 ?
                        `${props.document.name.substring(0,40)}... (${props.document.extension})` :
                        `${props.document.name}.${props.document.extension}`:`${props.document.name}.${props.document.extension}` }

                </td>

                <td>{props.document.createdBy}</td>
                <td>{(new Date(parseInt(props.document.created.substring(6, 19)))).toLocaleString()}</td>
                <td>{props.document.modifiedBy.includes('(') ? props.document.modifiedBy.substring(0, props.document.modifiedBy.indexOf('(')) : props.document.modifiedBy}</td>
                <td>{(new Date(parseInt(props.document.modified.substring(6, 19)))).toLocaleString()}</td>

                <td>{props.document.clip}</td>
                {/* <td>{props.document.docDate}</td> */}
                <td>{props.document.docDate !== '-' ? (new Date(parseInt(props.document.docDate.substring(6, 19)))).toLocaleDateString(): props.document.docDate}</td>
                <td>{props.document.workspaceCategory}</td>
                
            </tr>
        </>
    )
}

export default DocumentListItem;
