import React, { useState, useEffect } from 'react'
import axios from 'axios'

import DocumentListItem from './DocumentListItem'
import Loading from './LoadingAnimation/Loading'

import { CSVLink } from "react-csv";
import Select from 'react-select';



const Form = (props) => {

    const token = props.token
    const [searchParams, setSearchParams] = useState({
        clientId: '',
        matterId: '',
    })

    const [IDs, setIDs] = useState({
        clientId: '',
        matterId: '',
    })

    // data from z E-Storage(NG-0CJZAK1O) cabinet
    const [data1, setData1] = useState([])

    // data from LBBS Docs(NG-9UX9T8QX) cabinet
    const [data2, setData2] = useState([])

    // data from both cabinets
    const [allData, setAllData] = useState([])

    const [report1, setReport1] = useState([])
    const [report2, setReport2] = useState([])
    const [reportSelected, setReportSelected] = useState('allData')

    //client name and matter name from netDocuments
    const [clientName, setClientName] = useState()
    const [matterName, setMatterName] = useState()


    const [sortSelected, setSortSelected] = useState('modified')
    const [sortSelected2, setSortSelected2] = useState()

    // next link from z E-Storage(NG-0CJZAK1O) cabinet
    const [nextURL1, setNextURL1] = useState(null)

    //next link from LBBS Docs(NG-9UX9T8QX) cabinet
    const [nextURL2, setNextURL2] = useState(null)

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const [csvData, setCsvData] = useState([])

    let count = 0
    let useEffectCounter = 0

    useEffect(() => {
        if (data1[0] && data2[0]) {
            setAllData([...allData, ...data1, ...data2])
            useEffectCounter += 1
            console.log('useEffectCounter', useEffectCounter)
            setLoading(false)
            setData1([])
            setData2([])
        }

        if (allData[0]) {
            if (data1[0] || data2[0]) {
                setAllData([...allData, ...data1, ...data2])
                useEffectCounter += 1
                console.log('useEffectCounter', useEffectCounter)
                setLoading(false)
                setData1([])
                setData2([])
            }
            createReports()
        }

        csvCompiler('allData')
    }, [data1, data2])


    const revokeToken = () => {
        axios
            .post(`https://api.vault.netvoyage.com/v1/oauth/revoke`, {
                token: token
            }, { 
                headers: {
                Authorization: `Bearer ${token}`
            }},

            )
            .then(res => {
                console.log('res', res)
                props.setAccessToken(null)
            })
            .catch(err => console.log('err', err))
    }


    // ****************Sort functions***********************

    // last modified date
    const modified = (a, b) => {
        const A = a.modified;
        const B = b.modified;
        let comparison = 0;
        if (A < B) {
            comparison = 1;
        } else {
            comparison = -1;
        }
        return comparison;
    };

    const modified_by = (a, b) => {
        const A = a.modifiedBy.toUpperCase();
        const B = b.modifiedBy.toUpperCase();
        let comparison = 0;
        if (A > B) {
            comparison = 1;
        } else {
            comparison = -1;
        }
        return comparison;
    };

    //created date
    const created = (a, b) => {
        const A = a.created;
        const B = b.created;
        let comparison = 0;
        if (A < B) {
            comparison = 1;
        } else {
            comparison = -1;
        }
        return comparison;
    };

    const created_by = (a, b) => {
        const A = a.createdBy.toUpperCase();
        const B = b.createdBy.toUpperCase();
        let comparison = 0;
        if (A > B) {
            comparison = 1;
        } else {
            comparison = -1;
        }
        return comparison;
    };

    //name of document
    const name = (a, b) => {
        const A = a.name.toUpperCase();
        const B = b.name.toUpperCase();
        let comparison = 0;
        if (A > B) {
            comparison = 1;
        } else {
            comparison = -1;
        }
        return comparison;
    };

    //NDDOCID
    const id = (a, b) => {
        const A = a.id;
        const B = b.id;
        let comparison = 0;
        if (A < B) {
            comparison = 1;
        } else {
            comparison = -1;
        }
        return comparison;
    };

    const cabinet = (a, b) => {
        const A = a.cabinet.toUpperCase();
        const B = b.cabinet.toUpperCase();
        let comparison = 0;
        if (A < B) {
            comparison = 1;
        } else {
            comparison = -1;
        }
        return comparison;
    };

    const clip = (a, b) => {
        const A = a.clip
        const B = b.clip
        let comparison = 0;
        if (A < B) {
            comparison = 1;
        } else {
            comparison = -1;
        }
        return comparison;
    };


    const docDate = (a, b) => {
        const A = a.docDate
        const B = b.docDate
        let comparison = 0;
        if (A < B) {
            comparison = 1;
        } else {
            comparison = -1;
        }
        return comparison;
    };

    const workspaceCategory = (a, b) => {
        const A = a.workspaceCategory
        const B = b.workspaceCategory
        let comparison = 0;
        if (A > B) {
            comparison = 1;
        } else {
            comparison = -1;
        }
        return comparison;
    };



    // *******************sorting functionality********************
    const reportPicker = () => {
        if (reportSelected === 'allData') {
            return allData
        } else if (reportSelected === 'report1') {
            return report1
        } else if (reportSelected === 'report2') {
            return report2
        } else {
            return [{ name: 'something went wrong' }]
        }
    }

    let secondarySorting = reportPicker()

    if (!sortSelected2) {

    } else {
        if (sortSelected2 === 'cabinet') {
            secondarySorting.sort(cabinet)
        } else if (sortSelected2 === 'id') {
            secondarySorting.sort(id)
        } else if (sortSelected2 === 'name') {
            secondarySorting.sort(name)
        } else if (sortSelected2 === 'created') {
            secondarySorting.sort(created)
        } else if (sortSelected2 === 'created_by') {
            secondarySorting.sort(created_by)
        } else if (sortSelected2 === 'modified') {
            secondarySorting.sort(modified)
        } else if (sortSelected2 === 'modified_by') {
            secondarySorting.sort(modified_by)
        } else if (sortSelected2 === 'clip') {
            secondarySorting.sort(clip)
        } else if (sortSelected2 === 'docDate') {
            secondarySorting.sort(docDate)
        } else if (sortSelected2 === 'workspaceCategory') {
            secondarySorting.sort(workspaceCategory)
        } else {
            console.log('something with sorting is broken')
        }
    }
    secondarySorting.reverse()

    let sortedDocuments = secondarySorting

    if (sortSelected === 'cabinet') {
        sortedDocuments.sort(cabinet)
    } else if (sortSelected === 'id') {
        sortedDocuments.sort(id)
    } else if (sortSelected === 'name') {
        sortedDocuments.sort(name)
    } else if (sortSelected === 'created') {
        sortedDocuments.sort(created)
    } else if (sortSelected === 'created_by') {
        sortedDocuments.sort(created_by)
    } else if (sortSelected === 'modified') {
        sortedDocuments.sort(modified)
    } else if (sortSelected === 'modified_by') {
        sortedDocuments.sort(modified_by)
    } else if (sortSelected === 'clip') {
        sortedDocuments.sort(clip)
    } else if (sortSelected === 'docDate') {
        sortedDocuments.sort(docDate)
    } else if (sortSelected === 'workspaceCategory') {
        sortedDocuments.sort(workspaceCategory)
    } else {
        console.log('something with sorting is broken')
    }


    //*******************initial api call********************* (if this returns, then we get the document data)
    const getClientandMatterInfo = () => {

        axios
            .get(`https://api.vault.netvoyage.com/v1/Workspace/NG-0CJZAK1O/${searchParams.clientId}/${searchParams.matterId}/info`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(res => {
                // console.log('new response:')
                // console.log(res.data.customAttributes)
                setClientName(res.data.customAttributes[1].description)
                setMatterName(res.data.customAttributes[0].description)
                setIDs(searchParams)
                getDocuments()


            })
            .catch(err => {
                console.log('error:')
                console.log(err)
                setError(JSON.stringify(err))
                setLoading(false)

            })
    }

    //get documents from both cabinets
    const getDocuments = () => {
        axios
            .get(`https://api.vault.netvoyage.com/v1/Search/NG-0CJZAK1O/?q=%3d1003({${searchParams.clientId}})%3d1004({${searchParams.matterId}})%3d1011({Client Authorizations} OR {Client Documents} OR {Client Reports} OR {Correspondence} OR {Email})&$select=10,11&$orderby=lastMod`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(res => {
                console.log('z E-Storage(NG-0CJZAK1O) response:')
                console.log(res.data.customList)
                let data = res.data.customList.map((document) => {
                    
                    let doc = {
                        cabinet: 'z E-Storage',
                        id: document.standardAttributes.id,
                        name: document.standardAttributes.name,
                        createdBy: document.standardAttributes.createdBy,
                        created: document.standardAttributes.created,
                        modifiedBy: document.standardAttributes.modifiedBy,
                        modified: document.standardAttributes.modified,
                        clip: document.customAttributes[0].id === 11 ? document.customAttributes[0].value : document.customAttributes[1].value,
                        docDate: document.customAttributes[0].id === 10 ? document.customAttributes[0].valueDate : '-',
                        workspaceCategory: '-',
                        extension: document.standardAttributes.extension
                    }
                    return doc
                })
                setData1(data)
                if (res.data.next) {
                    setNextURL1(res.data.next)
                }
            })
            .catch(err => {
                console.log('error:')
                console.log(err)

            })

        axios
            .get(`https://api.vault.netvoyage.com/v1/Search/NG-9UX9T8QX/?q=%3d1003({${searchParams.clientId}})%3d1004({${searchParams.matterId}})&$select=24&$orderby=lastMod`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(res => {
                console.log('LBBS Docs(NG-9UX9T8QX) response:')
                console.log(res.data.customList)
                let data = res.data.customList.map((document) => {
                    let doc = {
                        cabinet: 'LBBS Docs',
                        id: document.standardAttributes.id,
                        name: document.standardAttributes.name,
                        createdBy: document.standardAttributes.createdBy,
                        created: document.standardAttributes.created,
                        modifiedBy: document.standardAttributes.modifiedBy,
                        modified: document.standardAttributes.modified,
                        clip: '-',
                        docDate: '-',
                        workspaceCategory: document.customAttributes[0] ? document.customAttributes[0].value : '-',
                        extension: document.standardAttributes.extension
                    }
                    return doc
                })
                setData2(data)
                if (res.data.next) {
                    setNextURL2(res.data.next)
                }
            })
            .catch(err => {
                console.log('error:')
                console.log(err)
            })

    }

    // if we have a next url, then you can push button at the bottom to get the next data. (1000 document cap for each call)
    const requestMoreDocuments = () => {
        setLoading(true)
        if (nextURL1) {
            let url = nextURL1
            setNextURL1(null)
            axios
                .get(`https://api.vault.netvoyage.com${url}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(res => {
                    console.log(`${url.substring(11, 22)} response:`)
                    console.log(res.data.customList)
                    let data = res.data.customList.map((document) => {
                        let doc = {
                            cabinet: 'z E-Storage',
                            id: document.standardAttributes.id,
                            name: document.standardAttributes.name,
                            createdBy: document.standardAttributes.createdBy,
                            created: document.standardAttributes.created,
                            modifiedBy: document.standardAttributes.modifiedBy,
                            modified: document.standardAttributes.modified,
                            clip: document.customAttributes[0].id === 11 ? document.customAttributes[0].value : document.customAttributes[1].value,
                            docDate: document.customAttributes[0].id === 10 ? document.customAttributes[0].valueDate : '-',
                            workspaceCategory: '-',
                            extension: document.standardAttributes.extension
                        }
                        return doc
                    })
                    setLoading(false)
                    setData1(data)
                    if (res.data.next) {
                        setNextURL1(res.data.next)
                    }
                })
                .catch(err => {
                    console.log('error:')
                    console.log(err)
                })
        }

        if (nextURL2) {
            let url = nextURL2
            setNextURL2(null)
            axios
                .get(`https://api.vault.netvoyage.com${url}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(res => {
                    console.log(`${url.substring(11, 22)} response:`)
                    console.log(res.data.customList)
                    let data = res.data.customList.map((document) => {
                        let doc = {
                            cabinet: 'z E-Storage',
                            id: document.standardAttributes.id,
                            name: document.standardAttributes.name,
                            createdBy: document.standardAttributes.createdBy,
                            created: document.standardAttributes.created,
                            modifiedBy: document.standardAttributes.modifiedBy,
                            modified: document.standardAttributes.modified,
                            clip: '-',
                            docDate: '-',
                            workspaceCategory: document.customAttributes[0] ? document.customAttributes[0].value : '-',
                            extension: document.standardAttributes.extension
                        }
                        return doc
                    })
                    setLoading(false)
                    setData2(data)
                    if (res.data.next) {
                        setNextURL2(res.data.next)
                    }
                })
                .catch(err => {
                    console.log('error:')
                    console.log(err)
                })
        }
    }

    const exportReportPicker = () => {
        if (reportSelected === 'allData') {
            return allData
        } else if (reportSelected === 'report1') {
            return report1
        } else if (reportSelected === 'report2') {
            return report2
        } else {
            return allData
        }
    }

    const csvCompiler = (report) => {
        let csvExport = []
        if (report === 'allData') {
            allData.map((doc) => {
                csvExport.push(
                    {
                        cabinet: doc.cabinet,
                        id: doc.id,
                        name: doc.name,
                        created_by: doc.createdBy,
                        date_created: (new Date(parseInt(doc.created.substring(6, 19)))).toLocaleString(),
                        modified_by: doc.modifiedBy,
                        date_modified: (new Date(parseInt(doc.modified.substring(6, 19)))).toLocaleString(),
                        clip: doc.clip,
                        docDate: doc.docDate ? (new Date(parseInt(doc.docDate.substring(6, 19)))).toLocaleDateString() : null,
                        workspaceCategory: doc.workspaceCategory
                    })
            })
            
        } else if (report === 'report1') {
            report1.map((doc) => {
                csvExport.push(
                    {
                        cabinet: doc.cabinet,
                        id: doc.id,
                        name: doc.name,
                        created_by: doc.createdBy,
                        date_created: (new Date(parseInt(doc.created.substring(6, 19)))).toLocaleString(),
                        modified_by: doc.modifiedBy,
                        date_modified: (new Date(parseInt(doc.modified.substring(6, 19)))).toLocaleString(),
                        clip: doc.clip,
                        docDate: doc.docDate ? (new Date(parseInt(doc.docDate.substring(6, 19)))).toLocaleDateString() : null,
                        workspaceCategory: doc.workspaceCategory
                    })
            })
        } else if (report === 'report2') {
            report2.map((doc) => {
                if (doc.extension.includes('ndfld') || doc.extension.includes('ndsq')) {
                                        
                } else {
                csvExport.push(
                    {
                        cabinet: doc.cabinet,
                        id: doc.id,
                        name: doc.name,
                        created_by: doc.createdBy,
                        date_created: (new Date(parseInt(doc.created.substring(6, 19)))).toLocaleString(),
                        modified_by: doc.modifiedBy,
                        date_modified: (new Date(parseInt(doc.modified.substring(6, 19)))).toLocaleString(),
                        clip: doc.clip,
                        docDate: doc.docDate ? (new Date(parseInt(doc.docDate.substring(6, 19)))).toLocaleDateString() : null,
                        workspaceCategory: doc.workspaceCategory
                    })}
            })
        }
        
        setCsvData(csvExport)
    }

    const inputHandler = (e) => {
        setSearchParams({ ...searchParams, [e.target.name]: e.target.value })
    }

    const sortHandler = (name) => {
        setSortSelected(name)
    }

    const sortHandler2 = (name) => {
        setSortSelected2(name)
    }

    const createReports = () => {
        setReport1(allData.filter((doc) => {
            if (doc.clip.toUpperCase().includes('CLIENT REPORTS')) {
                return true
            } else if (doc.clip.toUpperCase().includes('CLIENT AUTHORIZATION')) {
                return true
            } else if (doc.workspaceCategory.toUpperCase().includes('REPORTS & BUDGET')) {
                return true
            } else {
                return false
            }
        }))

        setReport2(allData.filter((doc => {
            if (doc.name.toUpperCase().includes('ATLP')) {
                return true
            } else {
                return false
            }
        })))
    }




    const submitHandler = (e) => {
        e.preventDefault()
        setAllData([])
        setError(null)
        setLoading(true)
        getClientandMatterInfo()
    }

    const options = [
        { value: 'cabinet', label: 'Cabinet' },
        { value: 'id', label: 'NDDOCID' },
        { value: 'name', label: 'Name' },
        { value: 'created_by', label: 'Created By' },
        { value: 'created', label: 'Date Created' },
        { value: 'modified_by', label: 'Modified By' },
        { value: 'modified', label: 'Date Modified' },
        { value: 'clip', label: 'Clip' },
        { value: 'docDate', label: 'Doc Date' },
        { value: 'workspaceCategory', label: 'Workspace Category' },
    ];

    const options2 = [
        { value: 'cabinet', label: 'Cabinet' },
        { value: 'id', label: 'NDDOCID' },
        { value: 'name', label: 'Name' },
        { value: 'created_by', label: 'Created By' },
        { value: 'created', label: 'Date Created' },
        { value: 'modified_by', label: 'Modified By' },
        { value: 'modified', label: 'Date Modified' },
        { value: 'clip', label: 'Clip' },
        { value: 'docDate', label: 'Doc Date' },
        { value: 'workspaceCategory', label: 'Workspace Category' },
    ];



    return (
        <div>
            <button style={{ position: "absolute", top: 30, right: 30 }} onClick={() => revokeToken()}>log out</button>
            {csvData[0] ? <div style={{ position: "absolute", top: 50, right: 30 }}><CSVLink data={csvData} filename={`${clientName} - ${matterName}.csv`}>export</CSVLink></div> : null}
            <div>
                <form style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', margin: 'auto', marginTop: 50, maxWidth: 500 }} onSubmit={(e) => submitHandler(e)}>
                    <label>
                        Client Number:
                <input name='clientId' onChange={(e) => inputHandler(e)} value={searchParams.clientId} />
                    </label>
                    <label>
                        Matter Number:
                <input name='matterId' onChange={(e) => inputHandler(e)} value={searchParams.matterId} />
                    </label>
                    <button>Load</button>
                </form>



                {clientName && matterName ? <h2>{clientName} - {matterName} ({IDs.clientId}-{IDs.matterId})</h2> : null}

                {allData[0] ?
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', }}>
                            <div style={{width: 200}}>
                            {/* <p>Primary Sorter</p> */}
                            <Select
                                value={options.filter((option) => option.value === sortSelected)}
                                onChange={(e) => sortHandler(e.value)}
                                // styles={customStyles}
                                // label="Primary Sorting"
                                options={options}
                            />
                            </div>
                            {/* <p>Secondary Sorter (optional)</p> */}
                            <div style={{width: 200}}>
                            <Select
                            // styles={customStyles}
                                value={options2.filter((option) => option.value === sortSelected2)}
                                label="Secondary Sorter (optional)"
                                onChange={(e) => sortHandler2(e.value)}
                                options={options2}
                            />
                            </div>
                        </div>
                        <div style={{cursor: 'pointer'}} className={'reports'}>
                            <div onClick={() => {
                                setReportSelected('allData')
                                csvCompiler('allData')
                            }}
                                style={{ height: '100%', padding: 15 }}
                                className={reportSelected === 'allData' ? 'reportSelected' : null}>Recent Activity (All Documents)</div>
                            <div onClick={() => {
                                setReportSelected('report1')
                                csvCompiler('report1')
                            }}
                                style={{ height: '100%', padding: 15 }}
                                className={reportSelected === 'report1' ? 'reportSelected' : null}>Recent Activity (Reports & Budgets)</div>
                            <div onClick={() => {
                                setReportSelected('report2')
                                csvCompiler('report2')
                            }}
                                style={{ height: '100%', padding: 15 }}
                                className={reportSelected === 'report2' ? 'reportSelected' : null}>ATLP</div>
                        </div>
                    </div>
                    : null}
            </div>
            <div>

                {allData[0] ?
                    <div style={{ maxWidth: '100%', maxHeight: 550, overflow: 'auto', margin: 'auto', }}>
                        <table>
                            <thead>
                                <tr style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                    <td name='cabinet' onClick={() => { sortHandler('cabinet') }} className={sortSelected === 'cabinet' ? 'selected' : null}>CABINET</td>
                                    <td name='id' onClick={() => { sortHandler('id') }} className={sortSelected === 'id' ? 'selected' : null}>NDDOCID</td>
                                    <td name='name' onClick={() => { sortHandler('name') }} className={sortSelected === 'name' ? 'selected' : null}>NAME</td>
                                    <td name='created_by' onClick={() => { sortHandler('created_by') }} className={sortSelected === 'created_by' ? 'selected' : null}>CREATED BY</td>
                                    <td name='created' onClick={() => { sortHandler('created') }} className={sortSelected === 'created' ? 'selected' : null}>DATE CREATED</td>
                                    <td name='modified_by' onClick={() => { sortHandler('modified_by') }} className={sortSelected === 'modified_by' ? 'selected' : null}>LAST MODIFIED BY</td>
                                    <td name='modified' onClick={() => { sortHandler('modified') }} className={sortSelected === 'modified' ? 'selected' : null}> DATE MODIFIED</td>
                                    <td name='clip' onClick={() => { sortHandler('clip') }} className={sortSelected === 'clip' ? 'selected' : null}>CLIP</td>
                                    <td name='docDate' onClick={() => { sortHandler('docDate') }} className={sortSelected === 'docDate' ? 'selected' : null}>DOC DATE</td>
                                    <td name='workspaceCategory' onClick={() => { sortHandler('workspaceCategory') }} className={sortSelected === 'workspaceCategory' ? 'selected' : null}>WORKSPACE CATEGORY</td>
                                </tr>
                            </thead>

                            <tbody>
                                {sortedDocuments.map(doc => {
                                    if (doc.extension.includes('ndfld') || doc.extension.includes('ndsq')) {
                                        
                                    } else {
                                    count += 1;
                                    return <DocumentListItem key={count} document={doc} />
                                    }
                                })}

                            </tbody>
                        </table>



                    </div>
                    : null}
                {!nextURL1 && !nextURL2 && !loading && allData[0] ? <p style={{ margin: 30 }}>End of List - {count} documents</p> : null}
                {nextURL1 || nextURL2 ? <p style={{ margin: 15 }}>We have received {count} documents from NetDocuments. Click Below to request more.</p> : null}
                {nextURL1 || nextURL2 ? <button style={{ margin: 30 }} onClick={() => requestMoreDocuments()}>Request More</button> : null}
                {error ? <p>{error}</p> : null}
                {loading ? <div><Loading /></div> : null}
            </div>
        </div>
    )
}

export default Form;