import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'



const Login = (props) => {

    let history = useHistory()
    const [ID, setID] = useState()
    const [envPass, setEnvPass] = useState(process.env.REACT_APP_PASSWORD)
    const [password, setPassword] = useState('')

    useEffect(() => {
        if (window.location.href.includes('access_token=')) {
            let url = window.location.href
            let access_token = url.substr(
                url.indexOf('=') + 1,
            )
            access_token = access_token.substring(0, access_token.indexOf('&'))
            console.log('encoded token', access_token)
            props.setEncodedToken(access_token)
            let decoded_access_token = decodeURIComponent(access_token)
            props.setToken(decoded_access_token)
            console.log('token:', decoded_access_token)
            history.push("/");
        }
        // setID(process.env.REACT_APP_ID)
        // setEnvPass(process.env.REACT_APP_PASSWORD)
    })

    const changeHandler =(e) => {
        setPassword(e.target.value)
    }

    const loginHandler = (e) => {
        e.preventDefault()
        if (password === envPass) {
            window.location.href = `https://vault.netvoyage.com/neWeb2/OAuth.aspx?client_id=%3C${process.env.REACT_APP_ID}%3E&scope=%3Cread%3E&response_type=token&redirect_uri=%3C${process.env.REACT_APP_URI}%3E`

        }
    }

    return (
        <form onSubmit={(e) => {loginHandler(e)}} style={{ display: 'flex', marginTop: 100, alignItems: 'center', justifyContent: 'center' }}>
            <input placeholder='password' type='password' onChange={(e) => {changeHandler(e)}} value={password}/>

            <button>log in</button>
        {/* <button onClick={() => console.log('passwords',password, envPass)}>log password</button> */}
        </form>
    )
}

export default Login;