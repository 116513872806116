import React, { useState } from 'react';

import Login from './components/Login';
import Form from './components/Form';

import './App.css';

function App() {

  const [token, setToken] = useState();
  const [encodedToken, setEncodedToken] = useState();


  return (
    <div className="App">
      {!token ?
        <Login setToken={setToken} setEncodedToken={setEncodedToken} /> : null}

      {token ?
        <Form token={token} encodedToken={encodedToken} setAccessToken={setToken} /> : null}


    </div>
  );
}

export default App;